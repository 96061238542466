$(window).load(function() {
});

$(function() {
    $('img').error(function () {
        $(this).hide();
    });

    $('.slick').slick() 

    $('.swipebox').swipebox();
});

$(document).foundation();
